<template>
  <div class="main">
    <div class="title">新建学生</div>
    <el-form :model="self"
             :rules="rules"
             ref="ruleForm"
             class="">
      <el-form-item label="学号/手机号："
                    style="width: 20%;display: flex;justify-content: flex-end;"
                    prop="verifiedMobile">
        <el-input placeholder="请输入"
                  :min="6"
                  v-model="self.verifiedMobile"></el-input>
      </el-form-item>
      <el-form-item label="学生姓名："
                    style="width: 20%;display: flex;justify-content: flex-end;"
                    prop="nickname">
        <el-input placeholder="请输入"
                  v-model="self.nickname"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary"
                   style="margin-left:126px"
                   @click="submitForm('ruleForm')">确定</el-button>
        <el-button @click="goback()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  data () {
    return {
      ruleForm: {
        verifiedMobile: '',
        nickname: '',
      },
      self: {

      },
      rules: {
        verifiedMobile: [
          { required: true, message: '至少6位数以上', trigger: 'blur', min: 6 },
        ],
        nickname: [
          { required: true, message: '请填写', trigger: 'change' }
        ],
      },


    };
  },
  mounted () {
    // document.getElementsByClassName('home_right')[0].style.background = '#f6f6f6'
    // document.getElementsByClassName('top_nav')[0].style.background = '#FFFFFF'
    // // document.getElementsByClassName('view_warp')[0].style.background = '#FFFFFF'
    // document.getElementsByClassName('view_warp')[0].style.margin = '24px'
    // document.getElementsByClassName('view_warp')[0].style.marginTop = '34px'
    // document.getElementsByClassName('view_warp')[0].style.height = 'auto'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginTop = '114px'
    // document.getElementsByClassName('right_select')[0].style.lineHeight = '5'
    // document.getElementsByClassName('left_icon')[0].style.marginLeft = '2px'
    // document.getElementsByClassName('el-breadcrumb')[0].style.marginLeft = '8px'

  },
  async created () {


  },
  watch: {
    'self': {
      handler (newVal) {
        // 当 self.subject_ids 的值发生改变时，执行相关操作


      },
      deep: true
    }
  },
  methods: {

    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        let params = {...this.self}

        if (valid) {
          this.$http({
            url: '/api/v1/tang/create_user',
            method: 'post',
            data:params
          }).then(res => {
            this.$notify({
              title: '提示',
              message: '提交成功!',
              type: 'success'
            });
            this.resetForm(formName)
            this.$router.push(
              {
                path: '/dataAnalysis',
                // query: {
                //   user_id: row.id,
                //   user_paper_id: row.user_paper_id
                // }
              })
          })

        } else {
          this.$notify({
            title: '提示',
            message: '提交失败!',
            type: 'error'
          });
          return false;
        }
      });
    },
    // async postData (params) {
    //   const { data } = await addTestReport(params)
    // },
    resetForm (formName) {
      this.$refs[formName].resetFields();

    },
    goback () {
      this.$router.go(-1)
    },
  }
}
</script>
<style lang="scss" scoped>
.main {
  font-family: Regular;
  // padding: 24px 24px 0 24px;
  padding: 12px;
  background: white;
  .title {
    top: 142px;
    left: 256px;
    width: 80px;
    height: 30px;
    // background: rgba(17, 17, 17, 1);
    margin: 8px 0px 30px 12px;
    color: rgba(17, 17, 17, 1);
    font-family: PingFang SC;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
}
::v-deep .el-button {
  padding: 0;
  width: 60px;
  height: 32px;
  margin-top: 16px;
}

::v-deep .el-form-item__content {
  margin-bottom: 6px;
}
::v-deep .el-form-item__label {
  font-size: 14px !important;
}
::v-deep .el-breadcrumb {
  margin-left: 5px;
}
.el-select-dropdown__item.selected::after {
  display: none !important;
}

.pedding {
  color: green;
}
</style>